import React from 'react'

import {Link} from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row,Card} from 'react-bootstrap'
import ThankYou from "../../images/nach.jpg"
import dance from "../../../content/image/cameron-mendes.jpg";
import easter from "../../../content/image/kinneth.jpg"
import freedom from "../../../content/image/amit.jpg"


import "./artistName.css"


const artist = () => (
    <div >         
        <Row className="artistInline">   
            <Card xs={4}  >
                <Link to="/artist/sheldon-bangera">
                    <Card.Img variant="top" src={ThankYou} /> 
                    <h6 className="text-white">Sheldon Bangera</h6>                   
                </Link>
            </Card>
            
        
            <Card xs={4}>
                <Link to="/artist/yeshua-ministries">
                    <Card.Img variant="top" src={dance} /> 
                    <h6 className="text-white">Yeshua ministries</h6>              
                </Link>
            </Card>
            

            <Card xs={4} >
                <Link to="/artist/kenneth-silway"> 
                    <Card.Img variant="top" src={easter} />
                    <h6 className="text-white">Kinneth Silway</h6>                
                </Link>
            </Card>
            

            <Card xs={4}>
                <Link to="/artist/amit-kamble">
                    <Card.Img variant="top" src={freedom} /> 
                    <h6 className="text-white">Amit Kamble</h6>                
                </Link>
            </Card> 
        </Row>    
    </div>

)


export default artist
