import React from 'react'

import {Link} from 'gatsby';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row,Card } from 'react-bootstrap'
import Aaradhna from "../../../content/image/Teri_Aradhana_Bridge_Music.jpg"
import Gaoon from "../../../content/image/gaaoon-hallelujah.jpg"
import Papa from "../../../content/image/papa.jpg"
import Zehnaseeb from "../../../content/image/zehenaseeb.jpg"
import YeshuMilGaya from "../../../content/image/yeshu-mil-gaya.png"
import {  FaAngleDoubleRight} from "react-icons/fa"
import "../categorySection/indexCategory.css"


export default function NewArrivals() {
    return(
        <div className="container">         
            <Row className="categoryBox">
                <Card xs={6}  >
                    <Link to="/Teri-Adradhna-Bridge-Music/">
                        <Card.Img variant="top" src={Aaradhna} />
                        <h3 className="exSongTitle">Aradhna</h3>   
                                
                    </Link>
                </Card> 
                <Card xs={6}  >
                    <Link to="/Gaaoon-Hallelujah-Nations-of-Worship/">
                    
                        <Card.Img variant="top" src={Gaoon} />                 
                        <h3 className="exSongTitle">Hallelujah</h3> 
                    </Link>
                </Card>
                <Card xs={6}  >
                <Link to="/Papa-Bridge-music/">
                    
                        <Card.Img variant="top" src={Papa} />                 
                        <h3 className="exSongTitle">Papa</h3> 
                    </Link>
                </Card>
                <Card xs={6}  >
                <Link to="/Zehenaseeb-yeshua-ministries/">
                    
                        <Card.Img variant="top" src={Zehnaseeb} />                 
                        <h3 className="exSongTitle">Zehenaseeb</h3> 
                    </Link>
                </Card>
                <Card xs={6}  >
                    <Link to="/Yeshu-mil-gaya-Sheldon-Bangera/">
                    
                        <Card.Img variant="top" src={YeshuMilGaya} />                 
                        <h3 className="exSongTitle">Mil Gaya</h3> 
                    </Link>
                </Card>
                
                <button xs={6} className="categoryBtn text-center ">
                    <Link to="/allsongs">                
                        <FaAngleDoubleRight size={40} ></FaAngleDoubleRight>                
                    </Link>
                </button>
            </Row>              
        </div>
    )

}

